import React from 'react';
import { Container, Grid, Paper, Typography, Button, Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';
import { colors } from '../../utils/colors/colors';
import map1 from'../../assets/map.png'
function Footer() {
    
    return (
        <Box sx={{ backgroundColor:colors.BRAND_FOOTER, padding: '20px',}}>

            <Container >
                <Grid container spacing={3}>

                    {/* Address and Contact Number Section */}
                    <Grid item xs={12} sm={4}>

                        <Box p={2}>
                            <Typography variant="h6" style={{color:colors.HEADER_MENU_COLOR,fontWeight: 'bold',fontFamily:"caveat,sans-serif "}}>Address</Typography>
                            <Typography color={"#fff"}>
                            address line1
                                <br />
                                address line2
                            </Typography>
                            <Typography variant="h6" style={{color:colors.HEADER_MENU_COLOR,fontWeight: 'bold',fontFamily:"caveat,sans-serif "}}>Contact</Typography>
                            <Typography color="#fff">Phone: 7900040117</Typography>
                            <Typography color="#fff">Email: @gmail.com</Typography>

                        </Box>

                    </Grid>

                    {/* Navigation Section */}
                    <Grid item xs={12} sm={4}>

                        <Box p={2} justifyContent="flex-start">
                            <Typography variant="h6" style={{color:colors.HEADER_MENU_COLOR,fontWeight: 'bold',fontFamily:"caveat,sans-serif "}}>Quick Links</Typography>
                            
                            {/* {content.navbar.menus.map((menu, i) => {
                           return ( <Box display="block"><Link to={menu.path} style={{ textDecoration: 'none', color: 'black'}}>{menu.name}
                            </Link> </Box>
                            )
                    })
                    } */}
                            <br />
                            <Typography variant="h6" style={{color:colors.HEADER_MENU_COLOR,fontWeight: 'bold',fontFamily:"caveat,sans-serif "}}>Follow Us</Typography>
                            <Box display="flex">
                                <IconButton  > <FacebookIcon sx={{color:"#fff"}} /> </IconButton>
                                <IconButton  ><TwitterIcon sx={{color:"#fff"}}/> </IconButton>
                                <IconButton > <InstagramIcon sx={{color:"#fff"}}/> </IconButton>

                            </Box>
                        </Box>

                    </Grid>

                    {/* Map Section */}
                    <Grid item xs={12} sm={4}>
                        <Paper>
                            {/* Replace with your map component */}
                            <Box height="250px" sx={{borderRadius:"12px"}}>
                            <img style={{width:"100%",height:"250px",borderRadius:"12px"}} src={map1}/>
                            </Box>
                        </Paper>
                    </Grid>



                </Grid>
            </Container>
        </Box>
    );
}

export default Footer;
