import { takeEvery } from "redux-saga/effects";
import { types } from "../../../redux/create_types";
import * as actions from "./Actions";
import createReducer from "./Reducer";
import { genericApiSaga } from "./Saga";
import { GET } from "../../../services/api_services/Api_calls";



export const TYPES =actions.createActionTypes(Object.keys(types))
export const ACTION=(type) => actions.createAsyncAction(type);
export const REDUCER=(type) => createReducer(type);

export function* CreateSaga(type,api,method,token) {
  yield takeEvery(`${type}_REQUEST`, function* (action) {
    const id =action.payload?action.payload.id:null
    const requestConfig = {
      url: id ? `${api}/${id>0?id:0}/` : api,
      method: method,
      body:method!=='GET'? action.payload:null,
      token:token
    };
    yield* genericApiSaga(requestConfig, type);
  });
}

