



// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import serviceWorker from './services/service_worker/ServiceWorker';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
// // serviceWorker()
// reportWebVitals();




// ========================> uncomment the following line if after initialization completes

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import ConfigureStore from "./zlab_lib/redux/root_redux/Store";

import serviceWorker from './services/service_worker/ServiceWorker';

const { store,persistor } = ConfigureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>     
          <App />  
    </Provider>
  </React.StrictMode>
);
serviceWorker()

reportWebVitals();