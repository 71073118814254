import Usage from "./components/Usage";
import RootRouter from "./services/navigation_service/root_router/RootRouter";
import ThemeFuctionProvider from "./utils/theme/ThemeFuction";

const App = () => {
  return (
    
      <ThemeFuctionProvider>
        <RootRouter/>
      </ThemeFuctionProvider>
      
  );
};

export default App;
