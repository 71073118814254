import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { colors } from '../../utils/colors/colors'
import { Container } from 'react-bootstrap'


function ContactUs() {
    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
      };
    
    return (
        <Box sx={{ backgroundColor: colors.BRAND_TEXTCOLOR ,paddingTop:"30px"}}>
            <Typography  align='center' variant="h4" sx={{color:"#fff",padding:"20px"}} > CONTACT US</Typography>
        <Container>
        <Grid container spacing={2} >
           

                <Grid item xs={12} md={6} lg={6}>
                    <Typography variant="h4" sx={{ color: colors.HEADER_BGC }}>ADRESS</Typography>
                    <Box>
                        {/* <Typography>
                            address line1<br />
                            address line2
                        </Typography> */}
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
      <Typography variant="h4" sx={{ color:  colors.HEADER_BGC}}>QUICK ENQUIRY</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Your Name"
          variant="outlined"
          margin="normal"
          fullWidth
          required
          sx={{ backgroundColor: '#fff' }} 
        />
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          margin="normal"
          fullWidth
          required
          sx={{ backgroundColor: '#fff' }} 
        />
        <TextField
          label="Message"
          multiline
          rows={4}
          variant="outlined"
          margin="normal"
          fullWidth
          required
          sx={{ backgroundColor: '#fff' }} 
        />
        <Button
          type="submit"
         
          
          sx={{
            '&:hover': {
                //backgroundColor:"whitesmoke",
                color: "black",
                transform: 'scale(1.08)',
                transition: 'transform 0.3s ease-in-out',
                //border: '1px solid',
                //borderColor: "#ffd180",
            },
            margin: '10px',
            textTransform: 'initial',
            alignContent: 'center',

            background: "transparent",
            color: "#fff"
        }}
        >
          Submit
        </Button>
      </form>
    </Grid>
                
        </Grid>
        </Container>
        </Box>
    )
}

export default ContactUs