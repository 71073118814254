import React, { useState } from 'react';
import { AppBar, Button, Toolbar, Typography, Drawer, List, ListItem, ListItemText, IconButton, Box, Hidden } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/logo/logo.svg';
import { Link } from 'react-router-dom';
import { colors } from '../../utils/colors/colors';
import { WhatsApp } from '@mui/icons-material';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen(open);
  };

  const menuItems = [
    { text: 'Home', link: '/' },
    { text: 'Products', link: '/products' },
    { text: 'About Us', link: '/aboutus' },
  ];

  return (
    <Box >
      <AppBar elevation={0} position="fixed" style={{ backgroundColor:colors.HEADER_BGC,height:"100px",justifyContent:"center" }}>
        <Toolbar>
        <Hidden lgUp>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
           
          </IconButton>
          </Hidden>
          <IconButton onClick={"/"}>
          <img style={{ height: '60px', width: '250px' }} src={logo} alt="Logo" />
          </IconButton>
          <Hidden lgDown>
          
          <Box sx={{display:"flex",justifyContent:"end",alignItems:"center",width:"100%",}}  >
          <Button style={{color:colors.HEADER_MENU_COLOR,}}  to="/">
            Home
          </Button>
          <Button style={{color:colors.HEADER_MENU_COLOR,marginLeft:"10px"}}  to="/products">
            Products
          </Button>
          <Button style={{color:colors.HEADER_MENU_COLOR,marginLeft:"10px"}} to="/aboutus">
            About Us
          </Button>
          <Button style={{color:colors.HEADER_MENU_COLOR,marginLeft:"10px"}} to="/aboutus">
           Contact Us
          </Button>
          <IconButton>
            <WhatsApp sx={{color:"green"}}/>
          </IconButton>
          </Box>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Hidden lgUp>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          {menuItems.map((item) => (
            <ListItem button key={item.text}  to={item.link} onClick={toggleDrawer(false)}>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      </Hidden>
    </Box>
  );
};

export default Header;
