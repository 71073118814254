import { Box, Grid, Typography } from '@mui/material';
import React from 'react'
import cola from '../../assets/partners/cola.png'
import limca from '../../assets/partners/limca.png'
import minute from '../../assets/partners/minute.png' 
import pepsi from '../../assets/partners/pepsi.png'
import { colors } from '../../utils/colors/colors';

function Our_partners() {
    const imageArray = [
       cola,
       limca,
       minute,
       pepsi
        // ... add more image URLs as needed
      ];
  return (
    <Box>
<Grid>
    <Typography align="center" color="black" letterSpacing={1} sx={{ fontSize: { xs: '30px', sm: '40px' }, fontWeight: 'bold',fontFamily:"caveat,sans-serif " }} ><span style={{color:colors.BRAND_TEXTCOLOR}}>O</span>ur Partners</Typography>
</Grid>


    <Grid container>
       
    <>
          {imageArray.map((imageUrl, index) => (
            <Grid key={index} xs={6} sm={6} md={3}>
              <Box sx={{ padding: { xs: '10px', sm: '20px', md: '30px' }, display: 'flex', justifyContent: 'center' }}>
                <img src={imageUrl} alt={`Image ${index + 1}`} style={{ height: '40px',width:"80px" }} />
              </Box>
            </Grid>
          ))}
        </>
    </Grid>
    </Box>
  )
}

export default Our_partners