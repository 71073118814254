import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react"; // Import useState and useEffect
import Layout from "../../../components/layout/Layout";
import NotFound from "../../../pages/404/NotFound";
import Home from "../../../pages/homescreen/Home";


const RootRouter = () => {
  
  return (
    
    <Layout>
      <BrowserRouter>
    <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<NotFound />} />
    </Routes>
    </BrowserRouter>
    </Layout>
   
  );
};

export default RootRouter;
