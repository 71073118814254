import {call, put, takeEvery} from 'redux-saga/effects';
import {callAPI} from '../../../services/api_services/Api_calls';



export function* genericApiSaga( config, base) {
 
  try {
    const response = yield call(callAPI, config);
    if (response) {
      yield put({type: `${base}_SUCCESS`, payload: response});
    } else {
      yield put({type: `${base}_FAILURE`});
    }
  } catch (error) {
    yield put({type: `${base}_FAILURE`, payload: error.message});
  }
}
