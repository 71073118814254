const initialState = {
  data: [],
  loading: false,
  error: null,
};

const createReducer = (base) => (state = initialState, action) => {
  switch (action.type) {
    case `${base}_REQUEST`:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
      };
    case `${base}_SUCCESS`:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case `${base}_FAILURE`:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case `${base}_DELETE`:
      return {
        ...state,
        loading: false,
        error: null,
        data: [],
      };
    default:
      return state;
  }
};

export default createReducer;
