
export const createActionTypes = base => ({
  REQUEST: `${base}_REQUEST`,
  SUCCESS: `${base}_SUCCESS`,
  FAILURE: `${base}_FAILURE`,
  DELETE: `${base}_DELETE`,

});

export const createAsyncAction = (base) => {
  const request = payload => ({type: createActionTypes(base).REQUEST, payload});
  const success = payload => ({type: createActionTypes(base).SUCCESS, payload});
  const failure = payload => ({type: createActionTypes(base).FAILURE});
  const delete_ = payload => ({type: createActionTypes(base).DELETE});

  return {request, success, failure,delete_};
};
