import React from 'react'
import { Box, Button, Container, Grid, ThemeProvider, Typography, createTheme } from '@mui/material'
import Products from '../Products/Products'
import About from '../About/About'
import Xcarousel from '../../components/carousel/Xcarousel'
import product_1 from '../../assets/products/masaladosa_powder.jpg'
import product_2 from '../../assets/products/pro3.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import Our_partners from '../partners/Our_partners'
import product1 from '../../assets/products1/product1.jpg'
import product2 from '../../assets/products1/product2.jpg'
import product3 from '../../assets/products1/product3.jpg'
import product4 from '../../assets/products1/product4.jpg'
import slide5 from '../../assets/slides/slider5.jpg'
import product5 from '../../assets/products/juice.png'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { colors } from '../../utils/colors/colors'
import ContactUs from '../contactus/ContactUs'
import slide1 from "../../assets/slides/slider8.jpg"
import slide2 from "../../assets/slides/slider7.jpg"
function Home() {


    return (

        <div>

            {/* carousel start */}
            <Container maxWidth={"false"}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box  >
                            {/* <Xcarousel /> */}
                            <img src={product_2} style={{ width: "100%", height: "700px" }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ top: "50" }} >
                        <Box sx={{ position: 'relative', top: '40%', width: '75%' }}>
                            <Typography variant='h4' >BRING IN THE ENERGY OF GOOD HEALTH TO YOUR LIFE!</Typography>

                            <Typography variant='caption'>A NOURISHING BREAKFAST, A SUMPTUOUS DINNER OR EVEN A SPECIAL SNACK TREAT – THERE’S NOTHING LIKE PANDA CEREAL FLOURS TO ENHANCE THE TASTE.</Typography>
                        </Box>
                    </Grid>


                </Grid>
            </Container>
            {/* carousel end */}

            {/* aboutus start */}

            <Grid container spacing={2}  >
                <Grid item xs={12} md={12} sx={{ backgroundColor: colors.BRAND_TEXTCOLOR }} >

                    <About />

                </Grid>

            </Grid>
            {/* aboutus end */}

            <Grid sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ position: 'relative' }}>
  <img src={slide1} style={{ width: '100%', }} alt="Background"  />
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // Add any additional styling for the overlay here
    }}
  >
                <Box sx={{
                    backgroundColor: "rgba(149, 110, 33, 0.30)",
                    padding: "30px",
                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                    backdropFilter: "blur(6.7px)",
                    WebkitBackdropFilter: " blur(6.7px)",
                    border: "1px solid rgba(149, 110, 33, 0.3)",
                    width: "50%"

                }}>
                    <Typography variant='h4' sx={{color:"#fff"}}>QUALITY POLICY</Typography>
                    <Typography  sx={{color:"#fff"}}
                    >Quality – it has always been our foremost concern. Quality is what every customer is after. It offers matchless value to their purchase. That is why we go
                        that extra mile in ensuring that all our products come to the market, ensuring the best of quality possible.</Typography>
                    <Button sx={{
                        '&:hover': {
                            //backgroundColor:"whitesmoke",
                            color: "black",
                            transform: 'scale(1.08)',
                            transition: 'transform 0.3s ease-in-out',
                            //border: '1px solid',
                            //borderColor: "#ffd180",
                        },
                        margin: '10px',
                        textTransform: 'initial',
                        alignContent: 'center',

                        background: "transparent",
                        color: "#fff"
                    }}>more</Button>
                </Box>
                </Box>
                </Box>
            </Grid>

            <Grid container justifyContent="center" alignItems="center" 
            
            >
                <Box sx={{ position: 'relative' }}>
  <img src={slide2} style={{ width: '100%', }} alt="Background"  />
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(149, 110, 33, 0.30)",
      backdropFilter: "blur(6.7px)",
                    WebkitBackdropFilter: " blur(6.7px)",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // Add any additional styling for the overlay here
    }}
  >
    <Box >
    <Typography align="center" variant="h4" color="white">
      PRODUCT RANGE
    </Typography>
    <Typography align="center" variant="body1" color="white">
      We offer our dear consumers a product range that is diverse, rich, and extensive. Have a look at them
    </Typography>
    </Box>
  </Box>
</Box>
            </Grid>
            
            <Box sx={{  backgroundColor: "rgba(149, 110, 33, 0.40)", }}>
                <Container maxWidth={"false"}>
                    <Grid container spacing={2} paddingTop={5} paddingBottom={5} >

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box sx={{borderRight:"2px solid #ddd"}}>
                            <Box>
                                <Typography variant='h4'>
                                    NEWS & EVENTS
                                </Typography>

                            </Box>
                            <Box sx={{ display: "flex",paddingTop:"20px",}}>
                                <Box sx={{ backgroundColor: "#fff", width: "80%", borderRadius: "50%", display:"flex",justifyContent:"center",alignItems:"center"}} >
                                    <Typography align="center" variant='h4'>feb <br />14</Typography>

                                </Box>
                                <Box marginLeft={4}>
                                    <Typography variant='h5'>Unveiling Of New Corporate Identity</Typography>
                                    <Typography variant='caption'>The concept of healthy food is gaining momentum,day by day. What you eat defines your health and access to healthy food with proper nutritional values in your right. Unhealthy,contaminated,pesticide
                                        laden food is causing more harm to your health than you imagine.</Typography>

                                </Box>
                            </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box>
                                <Typography variant='h4'>
                                    Latest Products
                                </Typography>
                            </Box>
                            <Grid container spacing={2} paddingTop={3}>

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Box sx={{ backgroundColor: "white" }}>
                                        <img src={product5} style={{ width: "100%", height: "250px" }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Box sx={{ backgroundColor: "white" }}>
                                        <img src={product5} style={{ width: "100%", height: "250px" }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Box sx={{ backgroundColor: "white" }}>
                                        <img src={product5} style={{ width: "100%", height: "250px" }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Box sx={{ backgroundColor: "white" }}>
                                        <img src={product5} style={{ width: "100%", height: "250px" }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Box sx={{ backgroundColor: "white" }}>
                                        <img src={product5} style={{ width: "100%", height: "250px" }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>

                                    <Box sx={{ backgroundColor: "white" }}>
                                        <img src={product5} style={{ width: "100%", height: "250px" }} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Container>
            </Box>
<Box>
    <ContactUs/>
</Box>
        </div>

    )
}

export default Home