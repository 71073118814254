import React, { createContext, useContext, useEffect, useState } from "react";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";

const ThemeContext = createContext();

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

const ThemeFunctionProvider = ({ children }) => {
  const [mode, setMode] = useState();

  useEffect(() => {
    const storedTheme = JSON.parse(localStorage.getItem("darkMode"));
    if (storedTheme) {
      setMode(storedTheme);
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = mode === "light" ? "dark" : "light";
    setMode(newTheme);
    localStorage.setItem("darkMode", JSON.stringify(newTheme));
  };

  const themeMode = mode === "light" ? "dark" : "light";
  const ProjectTheme = createTheme({
    palette: {
      mode: themeMode,
      background: {
        default: themeMode === "light" ? "#fff" : "#0f1924",
        paper: themeMode === "light" ? "#eeeeee" : "#0e1b28",
        header: themeMode === "light" ? "#fff":'#0e1b28', 
      },
      text: {
        primary: themeMode === "light" ? "#000000" : "grey",
        secondary: themeMode === "light" ? "#666666" : "#fff",
      },
      divider: themeMode === "light" ? "#DDDDDD" : "#555555",
    },
  });

  console.log("theme==", themeMode);

  return (
    <ThemeContext.Provider value={{ toggleTheme, themeMode }}>
      <EmotionThemeProvider theme={ProjectTheme}>
        {children}
      </EmotionThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeFunctionProvider;



//================================>dont edit this page