import { combineReducers } from "redux";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { types } from "./create_types";
import { REDUCER } from "../zlab_lib/redux/redux_common/ReduxGenerator";

const LoginConfig = {
  key: "login",
  storage: storage,
  whitelist: ["data"],
};

const LoginReducer = persistReducer(LoginConfig, REDUCER(types.LOGIN));

const RootReducer = combineReducers({
  login: LoginReducer,
  store: REDUCER(types.GETSTORE),
  dash: REDUCER(types.DASH),
  register: REDUCER(types.REGISTER),
  
});
const rootReducer = (state, action) => {
  return RootReducer(state, action);
};
export default rootReducer;
