import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <img
        src="https://img.freepik.com/free-vector/error-404-concept-landing-page_52683-12188.jpg?size=626&ext=jpg&ga=GA1.1.223266506.1696500448&semt=ais" // Replace with your image URL
        alt="Page Not Found"
        style={{ width: '300px', marginBottom: '20px' }}
      />
      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
        Page Not Found
      </Typography>
      <Typography sx={{ marginBottom: '40px' }}>
        The page you are looking for might be under construction or does not exist.
      </Typography>
      
    </Box>
  );
};

export default NotFound;
