import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import WishMsg from '../wish_msg/WishMsg'
import { Row } from 'react-bootstrap'

const Layout = ({children}) => {
  return (
    <div>
        <Header/>
        {children}
        <Footer/>
        {/* <WishMsg/> */}
    </div>
  )
}

export default Layout
{/* <Box Row>
<Sidebar/>
<Header>
  {children}
  </Header>
</Box> */}