import React from 'react';
import { Box, Grid, Button, Typography, createTheme, ThemeProvider } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { colors } from '../../utils/colors/colors';


function About() {
    
   const aboutus = "ELLICKAL TRADERS is a dedicated marketing company established in 2009 and has worked with many famous brands since our beginning. We are working to connect consumers with branded products of the highest quality. We work along with a particular brand, associates in their production department, and finally help them in the distribution of their products."
const About=aboutus.split(' ')
    return (
        <div>
           
            <Box>
                <Grid sx={{
                    textAlign: 'center',
                    // padding: { xs: '20px', sm: '30px' } 
                }}>
                    <Box>
                        <Box sx={{ padding: { xs: '10px', sm: '20px', md: '30px' } }}>
                            <Grid>
                                <Typography letterSpacing={1} 
                                sx={{ fontSize: { xs: '30px', sm: '40px' },color:"#fff", fontWeight: 'bold',}}> <span style={{color:"fff"}}>A</span>bout us</Typography>
                            </Grid>
                            <Grid xs={12}>
                                <Typography style={{ padding: '20px 20px',color:"#fff" }}> {About.map((word,index)=>(
                                     <span style={{fontWeight:index<=1&&'bold',fontFamily:index<=1&&"caveat,sans-serif ",color:index<=1&&"fff"}}>{word}{" "}</span>
                                ))} </Typography>
                            </Grid>
                            <Grid xs={12}>
                                <Button sx={{
                                    '&:hover': {
                                        //backgroundColor:"whitesmoke",
                                        color: "black",
                                        transform: 'scale(1.08)',
                                        transition: 'transform 0.3s ease-in-out',
                                        //border: '1px solid',
                                        //borderColor: "#ffd180",
                                    },
                                    margin: '10px',
                                    textTransform: 'initial',
                                    alignContent: 'center',
                                    padding: '7px 35px',
                                    background:"transparent",
                                    color: "#fff"
                                }}>Read more</Button>
                            </Grid>
                        </Box>
                        {/* <Grid xs={12}>
                            <Box sx={{ margin: { xs: '0px 20px', sm: '0px 30px', md: '0px 100px', lg: ' 0px 200px' } }}  >
                                <video id="sampleMovie" src="videos/product_presentation.mp4" width="100%" controls autoPlay></video>
                            </Box>

                        </Grid> */}
                        {/* <Box style={{ padding: '30px' }}>

                            <Typography letterSpacing={1} sx={{ fontSize: { xs: '30px', sm: '40px' }, fontWeight: 'bold' }} >About Us</Typography>

                            <Typography sx={{ textAlign: 'start', padding: { xs: '10px', sm: '20px 30px', md: '20px 50px' } }}>

                                <PlayArrowIcon sx={{ marginRight: '10px' }} />content1
                                <br /><br />

                                <PlayArrowIcon sx={{ marginRight: '10px' }} />content2
                                <br /><br />

                                <PlayArrowIcon sx={{ marginRight: '10px' }} />content3
                                <br /><br />

                                <PlayArrowIcon sx={{ marginRight: '10px' }} />content4
                                <br /><br />

                                <PlayArrowIcon sx={{ marginRight: '10px' }} />content5

                            </Typography>
                        </Box> */}
                    </Box>
                </Grid>
            </Box>
           
        </div>
    );
}

export default About;

