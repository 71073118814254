import { GET, POST } from "../services/api_services/Api_calls";
import { apis } from "../services/api_services/Api_list";
import { CreateSaga } from "../zlab_lib/redux/redux_common/ReduxGenerator";
import { types } from "./create_types";




export function* loginSaga() {
  yield* CreateSaga(types.LOGIN, apis.token, POST);
}
export function* products() {
  yield* CreateSaga(types.GETSTORE, 'https://fakestoreapi.com/products', GET);
}




