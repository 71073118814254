import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from '@redux-saga/core';

import {createLogger} from 'redux-logger'
import { persistStore } from 'redux-persist';
import rootReducer from '../../../redux/RootReducer';
import RootSaga from './RootSaga';

const sagaMiddleware = createSagaMiddleware();
const logger =createLogger()
const middleWare=[sagaMiddleware,logger]

const ConfigureStore=()=>{
    const store = createStore(rootReducer, applyMiddleware(...middleWare));
    let persistor=persistStore(store)
    sagaMiddleware.run(RootSaga);
    return {persistor, store};
}
export default ConfigureStore




