

export const types = {
  LOGIN: "LOGIN",
  GETSTORE: "GETSTORE",
  DASH:'DASH',
  REGISTER: "REGISTER",
};


