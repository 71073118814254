export const colors = {
    primary: '#007bff',
    secondary: '#6c757d',
    success: '#28a745',
    danger: '#dc3545',
    warning: '#ffc107',
    info: '#17a2b8',
    light: '#f8f9fa',
    dark: '#000',


    red: '#FF0000',
    blue: '#0000FF',
    green: '#00FF00',
    yellow: '#FFFF00',
    orange: '#FFA500',
    purple: '#800080',
    pink: '#FFC0CB',
    brown: '#A52A2A',
    teal: '#008080',
    gray: '#808080',
    borderColor: '2px solid white',

    BRAND_BUTTONCOLOR: '#F55B25',
    BRAND_BGCOLOR: '#ded157',
    BRAND_TEXTCOLOR: '#956e21',
    HEADER_BGC:"#2e2a2b",
    HEADER_MENU_COLOR:"#F4E984",
    BRAND_FOOTER:"#2e2a2b"
    



};
export const textColor = {
    border: '2px solid white'
}

