const release = "production";
const development = "development";

const Release_url = process.env.API_BASE_URL;
const development_url =''

const ENV = release;
//if ENV = development this will automatically take the development url to all

export const base_url = () => (ENV === release ? Release_url : null);
        