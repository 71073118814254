import axios from 'axios';
export const POST = 'POST';
export const GET = 'GET';
export const PUT = 'PUT';
// ================================> dont edit this file

export const callAPI = async ({url, method, body, token}) => {
  let header = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (token) {
    header['Authorization']=`Bearer ${token}`;
  }
  console.log('url', url, 'method', method, 'body', body, 'token', token);
  const response = await axios(url, {
    method,
    headers: header,
    data: body,
  })
    .then(res => {
      console.log('resp axios......>', res);
      return res.data;
    })
    .catch(error => {
      console.warn('error axios......>', error);
      return false;
    });
  return response;
};

export const postApi = async (data, url) => {
  console.log('data, url ::', data, url);
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json charset=utf-8',
      },
      body: JSON.stringify(data),
    });

    console.log(JSON.stringify(data));
    const respJSON = response.json();
    console.log('response : ', response, respJSON);
    return respJSON;
  } catch (error) {
    console.log(error);
  }
};
